<template>
    <div
        class="mb-4 shadow-md border border-gray-200"
        :class="{
            'cursor-pointer hover:shadow-lg': !locked,
        }">
        <span
            tabindex="0"
            class="block py-4 px-6 relative text-gray-700"
            v-on="!locked ? {
                click: () => $emit('go-to-lesson', lesson),
                keyup: (evt) => evt.which === ENTER_KEY_CODE && $emit('go-to-lesson', lesson),
            } : {}">
            <div
                class="absolute left-0 h-full top-0 w-1 bg-gray-600"
                :class="{
                    'bg-green-600': lesson.completed,
                }"></div>
            <div class="flex flex-row justify-between">
                <span>{{ lesson.title }}</span>
                <span class="text-sm flex flex-row items-center">
                    <doneSvg
                        v-if="lesson.completed"
                        class="h-4 mr-2 fill-current" />
                    <timeSvg class="h-4 mr-1 fill-current" />{{ estimatedCompletionTime }}
                </span>
            </div>
        </span>
    </div>
</template>

<script>
import mixin from '@/components/tutorials/mixin';

export default {
    name: 'LessonItem',

    mixins: [mixin],

    props: {
        lesson: {
            type: Object,
            required: true,
        },

        locked: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            ENTER_KEY_CODE: 13,
        };
    },

    computed: {
        estimatedCompletionTime() {
            const lessonWords = this.lesson.content[0].content;
            const estimate = this.getEstimatedCompletionTime(lessonWords);
            return estimate;
        },
    },

    components: {
        doneSvg: () => import('@/assets/img/done.svg'),
        timeSvg: () => import('@/assets/img/time.svg'),
    },
};
</script>
