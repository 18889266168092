var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 shadow-md border border-gray-200",class:{
        'cursor-pointer hover:shadow-lg': !_vm.locked,
    }},[_c('span',_vm._g({staticClass:"block py-4 px-6 relative text-gray-700",attrs:{"tabindex":"0"}},!_vm.locked ? {
            click: () => _vm.$emit('go-to-lesson', _vm.lesson),
            keyup: (evt) => evt.which === _vm.ENTER_KEY_CODE && _vm.$emit('go-to-lesson', _vm.lesson),
        } : {}),[_c('div',{staticClass:"absolute left-0 h-full top-0 w-1 bg-gray-600",class:{
                'bg-green-600': _vm.lesson.completed,
            }}),_c('div',{staticClass:"flex flex-row justify-between"},[_c('span',[_vm._v(_vm._s(_vm.lesson.title))]),_c('span',{staticClass:"text-sm flex flex-row items-center"},[(_vm.lesson.completed)?_c('doneSvg',{staticClass:"h-4 mr-2 fill-current"}):_vm._e(),_c('timeSvg',{staticClass:"h-4 mr-1 fill-current"}),_vm._v(_vm._s(_vm.estimatedCompletionTime)+" ")],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }